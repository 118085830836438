import React from "react";
import moment from "moment";
import { Link } from "gatsby";
import styles from './TileListing.module.scss';

import suitcaseSvg from '../../static/images/suitcase.svg';

class TileListing extends React.Component {
  state = {
    activeIndex: undefined
  }

  getPostList() {
    const postList = [];
    const { postEdges } = this.props;
    postEdges.forEach(postEdge => {
      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
        readingTime: postEdge.node.fields.readingTime.text,
        locationName: postEdge.node.fields.locationName,
        html: postEdge.node.html
      });
    });
    return postList;
  }

  setActiveIndex = (index) => () => this.setState({activeIndex: index})

  render() {
    const postList = this.getPostList();
    const { activeIndex } = this.state;
    return (
      /* Tag list here. */
      <div className={styles.list}>
        {/* Your post list here. */
          postList.map( (post, index) => (
            <Link to={post.path} key={post.title}>
            <div key={index} className={styles.entry} style={{backgroundImage: `url("${post.cover}")`}}>
              <div className={styles.header}>
                
                  <h1 className={styles.title}>{post.title}</h1>
                
                <div className={styles.metadata}>
                  {moment(post.date).fromNow()}
                  {post.locationName && ` · ${post.locationName}`}
                  &nbsp;·&nbsp;
                  {post.readingTime}
                </div>
              </div>
            </div>
            </Link>
          ))}
      </div>
    );
  }
}

export default TileListing;
