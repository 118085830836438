import React from 'react';
import posed from 'react-pose';
// import { TransitionState } from 'gatsby-plugin-transition-link';

export const Fade = posed.div({
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
})

const FadeWrapper = ({ children }) => (
  // We're using the TransitionState component here to provide the current transition status to our pose
  // <TransitionState>
    // {({ transitionStatus }) => (
      <Fade
        pose={
          ['entering', 'entered'].includes(transitionStatus)
            ? 'visible'
            : 'hidden'
        }
      >
        {children}
      </Fade>
    // )}
  // </TransitionState>
)

export default FadeWrapper;