export default [
    {
        name: "Pack up!",
        location: "Washington, DC",
        date: "January 2019",
        image: "images/plan/thumbs/pack-up.png"
    },
    {
        name: "Fly to Santiago",
        description: "The long way south: 36 hours of travel",
        location: "through Baltimore, Atlanta, and Mexico City",
        date: "January 21st",
        image: "images/plan/thumbs/aeromexico.png"
    },
    {
        name: "Buy The Van",
        location: "Santiago, Chile",
        duration: "Two days",
        image: "images/plan/thumbs/buy-van.png"
    },
    {
        name: "Explore",
        description: "Beaches, museums, local life, and food",
        location: "Santiago, Valparaiso, and Vina del Mar",
        duration: "Two weeks",
        image: "images/plan/thumbs/valparaiso-and-vina-del-mar.png"
    },
    {
        name: "Head South to Adventure Country",
        description: "Climb volcanoes, raft rivers, and learn to master the #vanlife",
        location: "Pucon and Villarica, Chile",
        duration: "One week",
        image: "images/plan/thumbs/villarica.png"
    }, 
    {
      name: "Enter Argentina",
      description: "Beautiful Swiss mountain villages on glacial lakes",
      location: "Bariloche",
      duration: "One week",
      image: "images/plan/thumbs/bariloche.png"
    },
    {
      name: "Carretera Austral",
      description: "Chile's partially-paved highway to Patagonia",
      location: "Chiloe, Villa O'Higgins",
      duration: "One week",
      image: "images/plan/thumbs/carretera-austral.png"
    },
    {
      name: "Patagonia",
      description: "Finally put all that expensive clothing to work: hiking and climbing in beautiful scenery",
      location: "Punta Arenas, Ushuaia",
      duration: "Three weeks",
      image: "images/plan/thumbs/patagonia.png"
    },
    {
      name: "Argentinian Coastline",
      description: "Drive north to Buenos Aires, stopping at ranches and beach towns along the way",
      duration: "One week",
      image: "images/plan/thumbs/argentina-horse.png"
    },
    {
      name: "Buenos Aires",
      duration: "One week",
      image: "images/plan/thumbs/buenos-aires.png"
    },
    {
      name: "Montevideo",
      duration: "A few days",
      image: "images/plan/thumbs/montevideo.png"
    },
    {
      name: "Iguazu Falls",
      duration: "Overnight",
      image: "images/plan/thumbs/iguazu-falls.png"
    },
    {
      name: "Paraguay",
      duration: "A few days",
      image: "images/plan/thumbs/paraguay.png"
    },
    {
      name: "Bolivia",
      description: "Tour the salt flats at Salar de Uyuni, the landscapes of Lake Titicaca, and breathe a little deeper in the mountain air",
      duration: "A week",
      image: "images/plan/thumbs/bolivia.png"
    },
    {
      name: "Peru",
      description: "Choquequirao, Macchu Picchu, the Nazca lines, and paragliding over the ocean",
      duration: "Two weeks",
      image: "images/plan/thumbs/peru.png"
    }
]