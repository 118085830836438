import React from 'react';
import timelineEvents from '../../../data/timelineEvents';
import styles from './Timeline.module.scss';

const Timeline = () => (
  <React.Fragment>
    <div className={styles.timelineHeader}>
      The Grand Plan
    </div>
    <div className={styles.timeline}>
      <div className={styles.centerline} />
      {timelineEvents.map((event, index) => {
        const isOdd = index % 2 === 1;
        const cycleClassName = isOdd ? styles.odd : styles.even;
        const rotationAngle = (5 + Math.random() * 25) * (isOdd ? -1 : 1);
        return (
          <div key={index} className={`${styles.event} ${cycleClassName}`}>
            <div className={styles.info}>
              <div className={styles.title}>{event.name}</div>
              <div className={styles.description}>
                <p>
                  {event.duration}
                  {event.location && event.duration && ' in '}
                  {event.location}
                </p>
                {event.description && <p>{event.description}</p>}
              </div>
            </div>
            <div className={`${styles.polaroid} ${cycleClassName}`} style={{ transform: `rotate(${rotationAngle}deg)`, backgroundImage: event.image ? `url(${event.image})` : undefined }} />

          </div>
        )
      })}
    </div>
  </React.Fragment>
)


export default Timeline;