import React from 'react';
import { Link } from 'gatsby';
// import TransitionLink from 'gatsby-plugin-transition-link';
// import AniLink from "gatsby-plugin-transition-link/AniLink";
import posed from 'react-pose';
import { FaCameraRetro, FaChartBar, FaGem, FaGlobeAmericas } from 'react-icons/fa';

import siteTitle from '../../static/logos/site-title-2.svg';
import styles from './splash.module.scss';
import FadeWrapper from '../utils/FadeWrapper';

class SplashPage extends React.Component {
  state = {
    open: false
  }

  componentDidMount() {
    this.setState({ open: true })
  }
  
  render() {
    // <FadeWrapper>
    return <div className={styles.page}>
      <div className={styles.centerTitle}>
        <object data={siteTitle} width="500px"/>
      </div>
      <ActionWrapper className={styles.actions} pose={this.state.open ? 'open' : 'closed'}>
        <ActionItem>
          <Link to='/journals'><button type="button" className={styles.action}><FaGlobeAmericas /><br />Stories</button></Link>
        </ActionItem>
        <ActionItem>
          <Link to='/guides'><button type="button" className={styles.action}><FaGem /><br />Articles</button></Link>
        </ActionItem>
        <ActionItem>
          <a href='https://www.instagram.com/hiatussouth/'><button type="button" className={styles.action}><FaCameraRetro /><br />Photos</button></a>
        </ActionItem>
        <ActionItem>
          <Link to='/data'><button type="button" className={styles.action}><FaChartBar /><br />Numbers</button></Link>
        </ActionItem>
      </ActionWrapper>
      {/* <div className={styles.actions}>
      <AniLink paintDrip to='/journals' color="brown" exit={{ length: 2 }}><button type="button" className={styles.action}>The Stories</button></AniLink>
      <AniLink paintDrip to='/guides' color="brown"><button type="button" className={styles.action}>The Guides</button></AniLink>
      <AniLink to='/photos' color="brown"><button type="button" className={styles.action}>The Photos</button></AniLink>
      <AniLink paintDrip to='/data' color="brown"><button type="button" className={styles.action}>The Numbers</button></AniLink>
    </div> */}
    </div>
    // </FadeWrapper>
  }
}

export default SplashPage;

const ActionWrapper = posed.div({
  open: {
    opacity: 1,
    delayChildren: 1000,
    staggerChildren: 200
  },
  closed: { opacity: 0, delay: 300 }
});

const ActionItem = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: 20, opacity: 0 }
})